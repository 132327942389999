import React, { Suspense, useEffect } from 'react';
import './App.css';
import IntroPage from './containers/IntroPage/IntroPage';
import Layout from './containers/Layout/Layout';
import { Switch, Route, Redirect } from 'react-router-dom';
import Spinner from './Components/UI/Spinner/Spinner';
// import Contact from './Components/Contact/Contact';
import ReactGA from "react-ga";
ReactGA.initialize("G-Y2R046TEDN");

const Contact = React.lazy(() => import('./Components/Contact/Contact'))



function App() {
    useEffect(() => {
      // This line will trigger on a route change
      ReactGA.pageview(window.location.pathname + window.location.search);
    });
  return (
    <div className="App">
      <Layout>
        <Switch>
          <Route path='/contact' render={() => <Suspense
            fallback={<div
              className='Fallback'>
              <Spinner /></div>}><Contact /></Suspense>} />
          <Route path='/' component={IntroPage} />
          <Redirect to='/' />
        </Switch>
        {/* <IntroPage /> */}
        {/* <SectionWipes /> */}
      </Layout>
    </div>
  );
}

export default App;
