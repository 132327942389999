import React from 'react'
import classes from './Footer.module.css'

const foot = props => {
    return (
        <div className={classes.Foot}>
            {/* <a href='/'>twitter</a>
            <a href='/'>facebook</a>
            <a href='/'>linkdin</a> */}
            {/* <p>pls hire me!</p> */}
        </div>
    )
}

export default foot